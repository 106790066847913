@mixin social-icons_ul {
        display: block;
        width:auto;
        list-style: none;
        position: absolute;
        right: 2.5rem;
        bottom: 2.5rem;
        padding:0;
};
@mixin social-icons_a($bgcolor, $color){
    display:block;
    background-color: $bgcolor;
    border-radius: 100%;
//    font-size: 2pc;
    height: 100%;
//    width: 2em;
    transform: translateY(50%);
};
@mixin social-icons_li($bgcolor, $color) {
    li{
        text-align:center;
        margin: 1rem;
        a{
            @include social-icons_a($bgcolor, $color);
            :hover {
                @include social-icons_a($bgcolor, $color);
            }
        }
    }
};