@import "../../node_modules/bourbon-neat/core/neat";

$mobile: new-breakpoint(max-width 480px);
$tablet: new-breakpoint(max-width 767px);
$desktop: new-breakpoint(min-width 768px);

$content-layout: (
    columns: 3,
    gutter: 0px,
);

#content {
    @include grid-container;
    position: absolute;
    top: 0px;
    right:0px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  
    article {
        @include grid-column(1, $content-layout);
        height: calc(100vh - 3rem);
    }

    aside {
       @include grid-column(2, $content-layout);
       height: calc(100vh - 3rem);
    }
    footer {
        @include grid-column(3, $content-layout);
        height: 3rem;
        ul {
            @include grid-container;
            li {
                @include grid-column(1, $content-layout);
            }
        }
    }
 };