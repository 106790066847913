@import "./socialIcons.scss";
@import "./colors.scss";

html, body {
    min-height:100%;
    font-size: 1pc;
}
.visible {
    visibility: visible;
}

#content {
    background-image:url("../img/afb-1.jpg");
    background-size: cover;
    font-size: inherit;

    article {
        background-color:$article--background-color;
        font-size: 2rem;
        text-align:center;
        padding: 2em;
        box-sizing: border-box;
        p, h1 {
            margin:.5em 0;
        }
        img {
            width:80%;
        }
        form {
            font-size: 1rem;
            #form_input--fName, #form_input--lName {
                width: calc(50% - .25rem);
                display: inline;
                float:left;
                margin-left:.25rem;
            }
            label {
                display: none;
            }
        }
    }
    //Social Icons
    aside {
        background-color:$aside--background-color;
        ul {
            display: block;
            width:auto;
            list-style: none;
            position: absolute;
            right: 2.5rem;
            bottom: 4.5rem;
            padding:0;
            background-color: $social_ul--background-color;
            border-radius: 2em;
            li{
                text-align:center;
                margin: 1rem;
                a{
                    display:block;
                    background-color: $social_a--background-color;
                    border-radius: 100%;
                    font-size: 2pc;
                    height: 2em;
                    width: 2em;
                    i{
                        color:black;
                        font-style: normal;
                        transform:translateY(50%);                
                    }
                }
                a:hover {
                    background-color: $social_a-hover--background-color;
                }
            }
        }
    }
    footer {
        background-color:$footer--background-color;
        a:link {
            color: $font-color;
            text-decoration: none;
        }
        ul {
            list-style-type: none;
            li{
                text-align: center;
            }
        }
    }
}