$font-color: black;


//Social Links Colors
$social_a-hover--background-color: green;
$social_a--background-color: greenyellow;

//Cover Background Colors
$article--background-color: rgba(255,255,255,0.75);
$footer--background-color: $article--background-color;
$aside--background-color: rgba(255,255,255,0);
$social_ul--background-color: $article--background-color;
